<template>
  <div>
    <div class="d-flex justify-content-between">
      <div class="text-left mb-1">
        <h3>Filtros</h3>
        <div class="d-flex align-items-center">
          <b-form-select
            v-model="filter"
            :options="filterOptions"
          />

          <span
            v-if="filter !== '' && filter.indexOf('http') === -1"
            class="ml-2"
          >Total: {{ sumFilter }} </span>
        </div>
      </div>
      <div class="text-right mb-1">
        <b-button
          variant="gradient-primary"
          :disabled="isLoading"
          @click="loadAnalyses()"
        >
          <b-spinner
            v-if="isLoading"
            small
          />
          <feather-icon
            v-else
            icon="RefreshCwIcon"
          />
        </b-button>
      </div>
    </div>
    <div v-if="!isLoading">
      <b-table
        fixed
        :show-empty="!filteredResults.length"
        :items="filteredResults"
        :fields="fields"
      >
        <template #empty>
          <h4 class="text-center my-2">
            Nenhum resultado encontrado
          </h4>
        </template>

        <template #head(timestamp)>
          Análise
        </template>

        <template #cell(timestamp)="row">
          {{ row.item.name }}<br>
          {{ moment.unix(row.item.timestamp).format("DD/DD/YYYY HH:mm:ss") }}<br>
          <small>({{ row.item.timestamp }})</small>
        </template>

        <!-- eslint-disable vue/no-v-html -->
        <template #cell(url)="row">
          <div v-html="getPath(row.item.url).split('/').join('<wbr>/')" />
        </template>
        <!-- eslint-enable vue/no-v-html -->

        <template #head(durations)>
          Durações<br>
          <b-badge variant="info">
            Back
          </b-badge>
        &nbsp;
          <b-badge variant="warning">
            Front
          </b-badge>
        </template>

        <template #cell(durations)="row">
          <b-progress
            v-if="row.item.front_diff === 0"
            show-progress
            height="2rem"
            :max="1"
          >
            <b-progress-bar
              variant="info"
              :value="1"
            >
              Menos de 1s
            </b-progress-bar>
          </b-progress>
          <b-progress
            v-else
            show-progress
            height="2rem"
            :max="parseFloat(row.item.front_diff) || parseFloat(row.item.back_diff)"
          >
            <b-progress-bar
              v-if="parseFloat(row.item.begin_diff) > 0"
              variant="warning"
              :value="parseFloat(row.item.begin_diff)"
            >
              {{ parseFloat(row.item.begin_diff) }}
            </b-progress-bar>
            <b-progress-bar
              v-if="parseFloat(row.item.back_diff) > 0"
              variant="info"
              :value="parseFloat(row.item.back_diff)"
            >
              {{ parseFloat(row.item.back_diff) }}
            </b-progress-bar>
            <b-progress-bar
              v-if="parseFloat(row.item.end_diff) > 0"
              variant="warning"
              :value="parseFloat(row.item.end_diff)"
            >
              {{ parseFloat(row.item.end_diff) }}
            </b-progress-bar>
          </b-progress>
          <div class="text-center">
            <span>Tempo total: {{ row.item.front_diff }}</span>
          </div>
        </template>
        <template #cell(back)="row">
          <div>
            Data: {{ row.item.begin_at_back.substring(0, 19) || 'NC' | date('DD/MM/YYYY') }}
          </div>
          <div>
            Duração: {{ row.item.back_diff || 'NC' }}
          </div>
          <div>
            Início: {{ row.item.begin_at_back.substring(11, 23).replace('.', ' . ') || 'NC' }}
          </div>
          <div>
            Final: {{ row.item.end_at_back.substring(11, 23).replace('.', ' . ') || 'NC' }}
          </div>
        </template>
        <template #cell(front)="row">
          <div>
            Data: {{ (row.item.begin_at_front) ? row.item.begin_at_front.substring(0, 19) : null || 'NC' | date('DD/MM/YYYY') }}
          </div>
          <div>
            Duração: {{ row.item.front_diff || 'NC' }}
          </div>
          <div>
            Início: {{ (row.item.begin_at_front) ? row.item.begin_at_front.substring(11, 23).replace('.', ' . ') : null || 'NC' }}
          </div>
          <div>
            Final: {{ (row.item.end_at_front) ? row.item.end_at_front.substring(11, 23).replace('.', ' . ') : null || 'NC' }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import PerformanceAnalysisService from '@/services/performanceAnalysis'
import { formatDateTimeDbToView } from '@/utils/helpers'
import {
  BTable,
  BFormSelect,
  BBadge,
  BSpinner,
  BProgress,
  BProgressBar,
  BButton,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BTable,
    BFormSelect,
    BBadge,
    BSpinner,
    BProgress,
    BProgressBar,
    BButton,
  },
  data() {
    return {
      moment,
      isLoading: true,
      results: [],
      filter: '',
      fields: [
        { key: 'timestamp', sortable: false },
        { key: 'url', sortable: false },
        { key: 'durations', sortable: false },
        { key: 'back', sortable: false },
        { key: 'front', sortable: false },
      ],
    }
  },
  computed: {
    maxFrontDurationInSeconds() {
      return this.results.reduce((greatest, result) => Math.max(result.front_diff || 1, greatest), 1)
    },

    sumFilter() {
      if (this.filter && this.filter.indexOf('http') === -1) {
        const r = this.filteredResults.reduce((carry, item) => {
          const newCarry = carry
          if (item.begin_at_front
              && (
                carry.min === null
              || ((new Date(carry.min))) < (new Date(item.begin_at_front))
              )
          ) {
            newCarry.min = item.begin_at_front
          }

          if (item.end_at_front
            && (
              carry.max === null
              || ((new Date(carry.max))) < (new Date(item.end_at_front))
            )
          ) {
            newCarry.max = item.end_at_front
          }
          return newCarry
        }, { min: null, max: null })

        return (parseFloat((new Date(r.max)) - (new Date(r.min))) / 1000).toFixed(3)
      }

      return 0
    },

    filterOptions() {
      const values = [...new Set([
        '',
        ...this.results.map(m => (m.url)),
        ...this.results.map(m => (m.name)),
      ])]

      values.sort((a, b) => {
        if (a.indexOf('http') === b.indexOf('http')) {
          return 0
        }
        return a.indexOf('http') ? -1 : 1
      })

      return values.map(m => ({
        value: m,
        text: m === '' ? 'Todos' : m,
      }))
    },

    filteredResults() {
      if (this.filter && this.filter.indexOf('http') !== -1) {
        return this.results.filter(f => f.url === this.filter)
      }

      if (this.filter && this.filter.indexOf('http') === -1) {
        return this.results.filter(f => f.name === this.filter)
      }

      return this.results
    },
  },
  created() {
    this.loadAnalyses()
  },
  methods: {
    formatDateTimeDbToView,
    getPath(url) {
      return (new URL(url)).pathname
    },
    getDurationBarWidth(duration) {
      return (duration / this.maxFrontDurationInSeconds) * 100
    },
    async loadAnalyses() {
      try {
        this.isLoading = true
        const { data: { data } } = await PerformanceAnalysisService.list()
        this.results = data.map(m => ({
          ...m,
          back_diff: (m.back_diff / 1000).toFixed(3),
          front_diff: (m.front_diff / 1000).toFixed(3),
          begin_diff: (m.begin_diff / 1000).toFixed(3),
          end_diff: (m.end_diff / 1000).toFixed(3),
        }))
        this.$emit('results', this.results)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
