<template>
  <section id="conversation">
    <b-breadcrumb :items="breadcrumb" />

    <b-card-actions
      title="Análise de performance"
      class="form-filters"
      :action-close="false"
      :action-refresh="false"
      :action-collapse="true"
      @refresh="refreshStop('cardAction')"
    >
      <b-row>
        <b-col cols="5">
          <b-form-group
            label="Nome"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="pa_name"
              autocomplete="off"
              :disabled="isMeasuring"
            />
          </b-form-group>
          <b-form-group
            label="URL"
            label-for="url"
          >
            <b-form-input
              id="url"
              v-model="url"
              :disabled="isMeasuring"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="7"
          class="form-buttons text-right d-flex justify-content-end flex-wrap align-content-end"
        >
          <b-form-group
            label=""
            label-for="btn-buscar"
            style="margin-top: 20px"
          >
            <b-button
              id="btn-buscar"
              :disabled="isMeasuring"
              variant="gradient-primary"
              @click="measure"
            >
              <b-spinner
                v-if="isMeasuring"
                small
              />
              Calcular
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="isMeasuring">
        <b-col>
          <div class="text-center p-3">
            <b-button
              id="btn-buscar"
              variant="gradient-primary"
              @click="stopMeasuring"
            >
              Parar cálculo de performance
            </b-button>
          </div>
          <b-embed
            ref="iframe"
            type="iframe"
            aspect="16by9"
            frameborder="0"
            :src="src"
          />
        </b-col>
      </b-row>
    </b-card-actions>
    <b-card>
      <PerformanceAnalysisList
        ref="performance-list"
        @results="handleResult"
      />
    </b-card>
  </section>
</template>

<script>
import PerformanceAnalysisService from '@/services/performanceAnalysis'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BButton,
  BCard,
  BFormGroup,
  BCol,
  BRow,
  BFormInput,
  BBreadcrumb,
  BEmbed,
  BSpinner,
} from 'bootstrap-vue'
import toast from '@/mixins/toast'
import PerformanceAnalysisList from './List.vue'

export default {
  components: {
    BCardActions,
    BButton,
    BCard,
    BFormGroup,
    BCol,
    BRow,
    BFormInput,
    BBreadcrumb,
    BEmbed,
    BSpinner,
    PerformanceAnalysisList,
  },
  mixins: [toast],
  data() {
    return {
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Ferramentas',
          active: true,
        },
        {
          text: 'Análise de Performance',
          active: true,
        },
      ],
      listResults: [],
      pa_name: '',
      url: '',
      src: 'about:blank',
    }
  },
  computed: {
    isMeasuring() {
      return this.src !== 'about:blank'
    },
  },
  mounted() {
    window.addEventListener('message', this.onMessage, false)
  },
  unmounted() {
    window.removeEventListener('message', this.onMessage, false)
  },
  methods: {
    handleResult(event) {
      this.listResults = event
    },
    measure() {
      if (this.listResults.filter(f => f.name === this.pa_name).length !== 0) {
        this.toastDanger('Erro! Nome em uso', 'Escolha um nome único para a análise de performance')
        return
      }

      const timestamp = Math.floor(Date.now() / 1000)
      const url = new URL(this.url)
      url.searchParams.set('pa_timestamp', timestamp)

      if (this.pa_name.trim()) {
        url.searchParams.set('pa_name', this.pa_name)
      }

      this.src = url.toString()
    },
    onMessage(event) {
      if (
        event.origin !== document.location.origin
        || !(event.data && event.data.extra && event.data.extra.backendRequestId)
        || (this.$refs.iframe && this.$refs.iframe.querySelector('iframe').contentWindow !== event.source)
      ) return

      PerformanceAnalysisService.registerMeasure(event.data)
    },
    stopMeasuring() {
      this.src = 'about:blank'
      this.$refs['performance-list'].loadAnalyses()
    },
  },
}
</script>
