import useJwt from '@/auth/jwt/useJwt'

export default class PerformanceAnalysisService {
  static registerMeasure(data) {
    return useJwt.post('/performance-analysis', {}, data)
  }

  static list() {
    return useJwt.get('/performance-analysis/list')
  }
}
